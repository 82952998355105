import { Controller } from "@hotwired/stimulus"
import getSymbolFromCurrency from 'currency-symbol-map'

export default class extends Controller {
  
  static get targets() {
    return [
      'currency',
      'amountsContainer',
      'email',
    ]
  }
  static values = {
    currencySettings: Array
  }

  connect() {
    this.updateAmounts();
  }
  
  changeBanner(event) {
    const slide_id = event.target.id.split('_')[1];
    
    var elems = document.querySelectorAll(".dots__dot");
    [].forEach.call(elems, function(el) {
      if (el.id == event.target.id) {
        event.target.classList.add("dots__dot--active");
      } else {
        el.classList.remove("dots__dot--active");
      }
    });

    var elems = document.querySelectorAll(".slide");
    [].forEach.call(elems, function(el) {
      if (el.id == "slide_" + slide_id) {
        el.classList.remove("hide");
      } else {
        el.classList.add("hide");
      }
    });
  }

  updateAmounts() {
    const currency = this.currencyTarget.value;
    const amounts = this.getAmountsForCurrency(currency);
    this.displayAmounts(currency, amounts);
  }

  getAmountsForCurrency(currency) {
    return this.currencySettingsValue.filter(setting => setting.currency_name.toLowerCase() === currency.toLowerCase())
                                     .map(setting => setting.amount);
  }

  displayAmounts(currency, amounts) {
    const symbol = getSymbolFromCurrency(currency);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    amounts.sort((a, b) => a - b);

    let buttonsHtml = amounts.map((amount, index) => {
        return `
        <form action="${this.donatePath()}" method="post" data-action="home#donate">
          <input type="hidden" name="authenticity_token" value="${csrfToken}">
          <input type="hidden" name="form[currency]" value="${currency}">
          <input type="hidden" name="form[amount]" value="${amount}">
          <button type="submit" class="btn__outline" id="amount_${index + 1}">
            ${symbol} ${amount.toLocaleString()}
          </button>
        </form>`;
    }).join("");

    buttonsHtml += `
    <form action="${this.donatePath()}" method="get">
      <input type="hidden" name="authenticity_token" value="${csrfToken}">
      <input type="hidden" name="currency" value="${currency}">
      <input type="hidden" name="other" value="true">
      <button type="submit" class="btn__outline">Other</button>
    </form>`;

    this.amountsContainerTarget.innerHTML = buttonsHtml;
  }

  selectAmount(amount) {
    console.log(`Amount selected: ${amount}`);
  }

  donate(event) {
    event.preventDefault();
    const button = event.target;
    const amountValue = button.innerText.split(' ')[1].replace(/,/g, '');
    const currencyValue = this.currencyTarget.value;
    window.location.href = `${this.donatePath()}?currency=${currencyValue}&amount=${amountValue}`;
  }


  donatePath() {
      return '/donate';
  }

  subscribe(event) {
    let isValid = this.validateForm(this);
      
    if (!isValid) {
      event.preventDefault();
      return
    }
  }

  validateForm(target) {
    let isValid = true;
  
    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = target.querySelectorAll(requiredFieldSelectors);
  
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();
  
        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
  
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove("form__input-label-error");
        field.nextElementSibling.classList.add("hide");
      }
    });
      
    if (!isValid) {
      return false;
    }
  
    let invalidFields = target.querySelectorAll('input:invalid');
      
    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();
          
        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove("form__input-label-error");
        field.nextElementSibling.classList.add("hide");
      }
    });
      
    return isValid;
  }
}
